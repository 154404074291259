@use '../abstracts' as *;

.skills {
  flex-direction: column;
  align-items: center;
  text-align: center;

  display: flex;
  padding-top: 5rem;

  background: linear-gradient(var(--color-primary), var(--color-tertiary));
  color: white;

  @supports (background-attachment: fixed) {
    background-attachment: fixed;
  }

  h3 {
    margin-bottom: 3rem;
  }

  &-list {
    display: grid;
    margin-bottom: 1rem;
    width: 100%;

    li {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.9;
      text-align: left;
      margin-left: 1rem;
    }

    .fa {
      margin: 0 1rem;
    }
  }
}

@include respond(tablet) {
  .skills h3 {
    margin: 1rem 0 3rem 0;
  }

  .skills-list {
    margin-bottom: 0;

    li {
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 2;
      margin-left: 30%;
    }
  }
}

@include respond(desktop) {
  .skills {
    padding-top: 5rem;

    h3 {
      font-size: 4.5rem;
      margin-bottom: 7rem;
    }
  }

  .skills-list {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    grid-column-gap: 15rem;

    li {
      margin-left: 0;
    }

    .fa {
      margin: 0 0.5rem;
    }
  }

  .columns {
    flex-direction: row;
    position: relative;
    top: 1rem;

    width: 100%;

    .col h4 {
      font-size: 2.5rem;

      ul li {
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 2.5;
      }
    }
  }
}
