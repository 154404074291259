@use '../abstracts' as *;

.banner {
  padding: 0;
  height: 100vh;
  background-color: hsl(220, 30%, 10%);
  /* background-image: url("../images/cartographer.png"); */
  color: var(--color-main-heading);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.hero {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  text-align: center;

  &__heading {
    margin: auto;

    background-image: linear-gradient(#5affcc, #41f6ff, hsl(220, 60%, 50%));
    background-size: 200% 200%;
    background-clip: text;
    color: transparent;

    animation: gradient 5s infinite alternate;
    width: 83%;

    font-family: sans-serif;
    font-size: 2.7rem;
    font-weight: bold;
  }

  &__tagline {
    background-image: linear-gradient(#5affcc, #41f6ff, hsl(220, 60%, 50%));
    background-size: 150% 150%;
    background-clip: text;
    color: transparent;

    animation: gradient 5s infinite alternate;

    font-size: 1.3rem;
    font-style: italic;
    font-weight: lighter;
  }

  &__button {
    &:link {
      display: inline-block;
      margin-top: 10rem;
      color: hsl(220, 30%, 10%);

      animation: bounce 0.6s 2.5s 2;
      text-decoration: none;
      transition: transform 100ms ease-out;
    }

    &:visited {
      color: var(--color-primary);
      text-decoration: none;
    }

    &:hover {
      transform: translateY(-7px) scale(1.02);
    }

    &:hover > button {
      box-shadow: 0 6px 3px rgba(0, 0, 0, 0.4);
    }

    button {
      background-color: #41f6ff;
      border: none;
      border-radius: 50%;
      box-shadow: 0 6px 3px rgba(0, 0, 0, 0.5);
      color: inherit;
      cursor: pointer;
      height: 2rem;
      width: 2rem;
      outline: none;

      font-size: 1rem;
    }
  }

  // * ========== MEDIA QUERIES ============= *

  // === Tablet ===
  @include respond(tablet) {
    &__heading {
      font-size: 4rem;
    }

    &__tagline {
      font-size: 2rem;
    }
  }

  // ==== Desktop ====
  @include respond(desktop) {
    top: 40%;

    &__heading {
      width: 65%;
      font-size: 4.5rem;
    }

    &__tagline {
      font-size: 2.5rem;
    }

    &__button button {
      top: 70%;
      height: 2.5rem;
      width: 2.5rem;
      font-size: 1.2rem;
    }
  }
}
