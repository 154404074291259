/* #54D69B */

:root {
  /* COLORS */
  --color-primary: #354D9E;
  --color-secondary: #ECEDFA;
  --color-tertiary: #BF4154;
  /* --color-main-heading: #41E6FF; */
  --color-main-heading: hsl(200, 100%, 70%);
  --color-text: #0D0D0D;
  --color-dark: hsl(200, 10%, 10%);
  --color-light: hsl(200, 5%, 97%);

  /* OTHER */
  --shadow: 0 6px 6px rgba(0, 0, 0, .5);
}