@use '../abstracts' as *;

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color-dark);
  z-index: 1;
}

.navbar {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;

  &__link {
    height: 3rem;
    max-width: 7.3rem;
    fill: #555;
    stroke: #41e6ff;
    stroke-width: 1;
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    transition: all 1s;
    text-decoration: none;

    &.active,
    &:active,
    &:hover {
      stroke-dashoffset: 0;
      fill: var(--color-dark);
    }
  }

  li:nth-child(1) svg,
  li:nth-child(2) svg {
    max-width: 5.5rem;
  }

  li:nth-child(4) svg {
    max-width: 4.9rem;
  }

  &__desktop-menu {
    display: none;
  }

  &__mobile-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color-dark);
    height: 100vh;
    text-align: center;
    transform: translateY(-60rem);
    transition: 0.5s ease-out;
  }

  &__mobile-menu {
    height: 50vh;
    opacity: 0;
    transform: translateX(-20rem);
    transition: 0.5s 0.5s cubic-bezier(0.34, -0.46, 0.63, 1.93);

    li {
      margin: 2rem;
      font-size: 1.5rem;
    }

    a {
      text-decoration: none;
      color: var(--color-light);
    }
  }

  &__burger {
    position: relative;
    height: 1.5rem;
    width: 2.5rem;
    cursor: pointer;
    z-index: 2;

    span {
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: var(--color-main-heading);
      border-radius: 6px;
      transition: 0.3s;

      &:first-child {
        top: 0;
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child {
        bottom: 0;
      }
    }

    &.active {
      span:first-child {
        top: 50%;
        transform: rotate(45deg);
      }

      span:nth-child(2) {
        transform: scale(0);
      }

      span:last-child {
        top: 50%;
        transform: rotate(-45deg);
      }
    }

    &.active + .navbar__mobile-container {
      transform: none;
    }

    &.active + .navbar__mobile-container > .navbar__mobile-menu {
      opacity: 1;
      transform: none;
    }
  }

  // ========== MEDIA QUERIES ============
  // === Tablet ===
  @include respond(tablet) {
    justify-content: center;

    &__burger {
      display: none;
    }

    &__mobile-container {
      display: none;
    }

    &__desktop-menu {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 90%;
      line-height: 2;
      font-size: 1.3rem;
    }

    &__menu-bar {
      --start: 300px;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 0.5rem;
      width: 10%;
      background-color: #41e6ff;
      transform: translateX(var(--start));
      transition: 0.3s ease-out;
    }

    &__desktop-menu a {
      padding: 0 1rem;
      letter-spacing: 1px;
    }
  }

  // === Desktop ===
  @include respond(desktop) {
    .header {
      height: 5rem;
    }
    &__desktop-menu a {
      font-size: 1.7rem;
      transition: 0.3s;
    }

    &__desktop-menu a:hover {
      color: var(--color-main-heading);
    }
  }
}
