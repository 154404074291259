@use '../abstracts' as *;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  min-height: 100%;
  min-width: 100%;

  font-family: 'Montserrat', sans-serif;
}

/* KEEP TRANSITION ANIMATIONS FROM APPEARING ON PAGE LOAD */
.preload * {
  transition: none !important;
}

.wrapper {
  margin: 0 auto;
  max-width: 75rem;
}

section {
  padding: 5rem 0 3rem 0;

  @include respond(desktop) {
    min-height: 100vh;
  }
}

.section-para {
  margin: 3rem 0.5rem;
  line-height: 1.7;
  font-weight: normal;

  @include respond(tablet) {
    font-size: 1.4rem;
    font-weight: 300;
  }

  @include respond(desktop) {
    font-size: 1.5rem;
  }
}

.link {
  color: var(--color-primary);
  transition: color 0.4s;

  &:visited {
    color: var(--color-tertiary);
  }

  &:hover {
    color: var(--color-tertiary);
  }

  &:active,
  &:focus {
    color: #c84da6;
  }
}

.about,
.contact,
footer {
  background: var(--color-secondary);
}

h2,
h3,
h4 {
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 200;
}

h4 {
  display: inline-block;
  border-bottom: 1px solid white;
}

ul {
  list-style: none;
}

// * ============= MEDIA QUERIES ============== *

// === Tablet ===
@include respond(tablet) {
  h2,
  h3 {
    font-size: 3.5rem;
  }
}
